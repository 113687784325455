import { useState, useEffect, useRef, useContext } from "react";
import styles from "../styles/ChatMenu.module.scss";

import hamburger from "../img/hamburger.svg";

import ChatMenuHistory from "./ChatMenuHistory.js";
import ChatMenuHistoryConsent from "./ChatMenuHistoryConsent.js";

import { ApplicationContext } from "./contexts/ApplicationContext.js";
import { AuthContext } from "./contexts/AuthContext.js";

export default function ChatMenu({
  prompt,
  uniqueChatId,
  doHandleNewChat,
  doRecreateConversation,
}) {
  const { applicationConfig } = useContext(ApplicationContext);
  const { user } = useContext(AuthContext);
  const [menu, setMenu] = useState({
    isExpanded: false,
    modes: [],
  });

  const menuRef = useRef(null);

  const toggleMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }

    setMenu((prev) => ({
      ...prev,
      isExpanded: !prev.isExpanded,
    }));
  };

  // Filter modes based on user's groups and "visible" property in config
  // Includes modes where requiredGroupIds is not set or matches at least one of the user's groups
  useEffect(() => {
    const filteredModes = applicationConfig.modes.filter((mode) => {
      if (!mode.requiredGroupId) {
        return mode.visible;
      }

      const requiredGroups = mode.requiredGroupId
        .split(",")
        .map((id) => id.trim());

      const userBelongsToRequiredGroup = requiredGroups.some((groupId) =>
        user.groups.includes(groupId)
      );

      return userBelongsToRequiredGroup && mode.visible;
    });
    setMenu((prev) => ({ ...prev, modes: filteredModes }));
  }, [applicationConfig.modes, user.groups]);

  // Adding event listener when menu is expanded and removing it when collapsed
  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event) => {
      if (
        menu.isExpanded &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        // Clicked outside the menu, close menu
        toggleMenu();
      }
    };

    // Function to handle escape key press
    const handleEscapePress = (event) => {
      if (menu.isExpanded && event.key === "Escape") {
        toggleMenu();
      }
    };

    if (menu.isExpanded) {
      document.addEventListener("click", handleOutsideClick);
      document.addEventListener("keydown", handleEscapePress);
    }

    // Clean up the event listener on unmount or when the menu is collapsed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, [menu.isExpanded]);

  // Function to toggle prompt mode
  const togglePromptMode = async (e) => {
    const newMode = e.currentTarget.name;
    const assistantId = e.currentTarget.dataset.assistantId;

    await doHandleNewChat(newMode, assistantId);

    if (menu.isExpanded) {
      toggleMenu();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <button className={styles.hamburger} onClick={toggleMenu}>
          <img src={hamburger} alt="Öppna meny" width={40} />
        </button>
        {menu.modes.map((mode, index) => (
          <button
            name={mode.name}
            data-assistant-id={mode.assistantId || ""}
            onClick={togglePromptMode}
            className={[
              styles.modeButton,
              prompt.mode === mode.name ? styles.active : "",
            ].join(" ")}
            key={index}
          >
            <img src={mode.img} alt={mode.label} width={40} />
          </button>
        ))}
        <div className={styles.menu} ref={menuRef}>
          <div className={menu.isExpanded ? styles.expanded : styles.collapsed}>
            <ul className={styles.modes}>
              {menu.modes.map((mode, index) => (
                <li key={index}>
                  <button
                    name={mode.name}
                    data-assistant-id={mode.assistantId || ""}
                    onClick={togglePromptMode}
                    className={[
                      styles.modeButtonExpanded,
                      prompt.mode === mode.name ? styles.active : "",
                    ].join(" ")}
                  >
                    <img src={mode.img} alt={mode.label} width={40} />
                    {mode.label}
                  </button>
                </li>
              ))}
            </ul>
            {user.conversations ? (
              <ChatMenuHistory
                menu={menu}
                uniqueChatId={uniqueChatId}
                doRecreateConversation={doRecreateConversation}
                toggleMenu={toggleMenu}
              />
            ) : (
              <ChatMenuHistoryConsent />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
