import { assistantAzureRegexCheck } from "./assistantAzureRegexCheck.js";

// Custom error classes
class StreamInitError extends Error {
  constructor(message = "Kunde inte starta strömningen av svar") {
    super(message);
    this.name = "StreamInitError";
  }
}

class DataFormatError extends Error {
  constructor(message = "Ogiltig datastruktur i svaret") {
    super(message);
    this.name = "DataFormatError";
  }
}

class ContentPolicyError extends Error {
  constructor(message = "Content policy violation") {
    super(message);
    this.name = "ContentPolicyError";
  }
}

const ERROR_MESSAGES = {
  CONTENT_POLICY:
    "Meddelandet fastnade i Microsofts säkerhetsfilter. Detta kan ibland ske av misstag. Prova gärna att omformulera meddelandet.",
  STREAM_ERROR: "Kunde inte ta emot svaret från servern. Vänligen försök igen.",
  FORMAT_ERROR: "Ogiltigt svar från servern. Vänta en stund och försök igen.",
  NETWORK: "Kunde inte nå servern. Kontrollera din anslutning och försök igen.",
  PARSE_ERROR: "Kunde inte tolka svaret från servern. Vänligen försök igen.",
  UNEXPECTED:
    "Ett oväntat fel uppstod. Vänligen uppdatera sidan och försök igen.",
};

// Handler for stream processing errors
const handleStreamError = (error, addMessageBanner) => {
  console.error("Stream processing error:", error);
  const errorMessage =
    error instanceof DataFormatError
      ? ERROR_MESSAGES.FORMAT_ERROR
      : ERROR_MESSAGES.STREAM_ERROR;
  addMessageBanner({
    position: "topMiddle",
    type: "failure",
    text: errorMessage,
  });
};

// Handler for response errors
const handleResponseError = async (response, addMessageBanner) => {
  try {
    const data = await response.json();
    if (data?.Error) {
      if (data.Error.includes("ResponsibleAIPolicyViolation")) {
        throw new ContentPolicyError();
      }
      throw new Error(data.Error);
    }
    throw new DataFormatError();
  } catch (error) {
    const errorMessage =
      error instanceof ContentPolicyError
        ? ERROR_MESSAGES.CONTENT_POLICY
        : error instanceof SyntaxError
        ? ERROR_MESSAGES.PARSE_ERROR
        : error instanceof DataFormatError
        ? ERROR_MESSAGES.FORMAT_ERROR
        : ERROR_MESSAGES.UNEXPECTED;

    addMessageBanner({
      position: "topMiddle",
      type: "failure",
      text: errorMessage,
    });
    console.error("Response handling error:", error);
  }
};

// Chunk processor
const processChunk = (chunk, text, hasReceivedThreadId, setBot, setPrompt) => {
  if (!hasReceivedThreadId) {
    try {
      const jsonChunk = JSON.parse(chunk);
      if (jsonChunk.thread_id) {
        setPrompt((prev) => ({
          ...prev,
          threadId: jsonChunk.thread_id,
        }));
        return { hasReceivedThreadId: true, text };
      }
    } catch (e) {
      // Not JSON, continue with regular processing
    }
  }

  if (chunk.startsWith("replace|")) {
    const [_, oldUrl, newId] = chunk.split("|");
    if (!oldUrl || !newId) {
      throw new DataFormatError("Ogiltig filersättning i svaret");
    }
    text = text.replace(oldUrl, `/file/${newId}`);
  } else {
    text += chunk;
    setBot((prev) => ({
      ...prev,
      response: prev.response + chunk,
    }));
  }

  return { hasReceivedThreadId, text };
};

// Handles user query submission
export const handleQuery = async (
  e,
  uniqueChatId,
  bot,
  setBot,
  chat,
  setChat,
  prompt,
  setPrompt,
  addMessageBanner,
  chatLabelRef
) => {
  e.preventDefault();

  const capturedPrompt = prompt.content;
  const capturedImage = prompt.base64Image;

  setBot({
    response: "",
    sources: [
      {
        content: "",
        file: "",
      },
    ],
  });

  setChat({
    ...chat,
    history: [
      ...chat.history,
      capturedImage ? [capturedPrompt, capturedImage] : capturedPrompt,
    ],
    waiting: true,
    latestPrompt: prompt.content,
  });

  setPrompt({
    ...prompt,
    content: "",
    nerResponse: "",
  });

  // Transform chat.history to remove second element (base64 images) of nested arrays
  const sanitizedHistory = chat.history.map((entry) => {
    if (Array.isArray(entry) && entry.length > 0) {
      // Return only the first element
      return entry[0];
    }

    return entry;
  });

  if (
    chat.requestAiLabel &&
    (prompt.mode === "GENERAL_AZURE" || assistantAzureRegexCheck(prompt.mode))
  ) {
    try {
      const response = await fetch("/api/conversations/generate-label", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          user_prompt: capturedPrompt,
          prompt_mode: prompt.mode,
          conversation_id: uniqueChatId,
          request_ai_label: chat.requestAiLabel,
        }),
      });

      if (response.ok) {
        const responseJson = await response.json();
        const newLabel = responseJson.Label;

        chatLabelRef.current = newLabel;

        await new Promise((resolve) => {
          setChat((prev) => {
            const updatedChat = {
              ...prev,
              label: newLabel,
              requestAiLabel: false,
            };
            resolve();
            return updatedChat;
          });
        });
      } else {
        console.error("Failed to generate AI label for conversation");
      }
    } catch (error) {
      console.log("Unable to generate AI label for conversation:", error);
    }
  }

  const formData = new FormData();
  formData.append("user_prompt", capturedPrompt);
  formData.append("prompt_image", capturedImage);
  formData.append("prompt_mode", capturedImage ? "GENERAL_AZURE" : prompt.mode);
  formData.append(
    "chat_history",
    JSON.stringify(chat.useHistory ? sanitizedHistory : [])
  );
  formData.append("chat_label", chatLabelRef.current);
  formData.append("conversation_id", uniqueChatId);
  formData.append("stream_response", chat.streamResponse);
  formData.append("assistant_id", prompt.assistantId);
  formData.append("thread_id", prompt.threadId);
  formData.append("attachments", JSON.stringify(chat.attachments));

  try {
    const response = await fetch("/api/gpt/prompt", {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      await handleResponseError(response, addMessageBanner);
      return;
    }

    if (chat.streamResponse) {
      const reader = response.body?.getReader();
      if (!reader) {
        throw new StreamInitError();
      }

      let text = "";
      let hasReceivedThreadId = false;

      const readChunk = async ({ done, value }) => {
        if (done) {
          setChat((prev) => ({
            ...prev,
            history: [
              ...chat.history,
              capturedImage ? [capturedPrompt, capturedImage] : capturedPrompt,
              text,
            ],
            waiting: false,
          }));
          return;
        }

        try {
          const chunk = new TextDecoder().decode(value);
          ({ hasReceivedThreadId, text } = processChunk(
            chunk,
            text,
            hasReceivedThreadId,
            setBot,
            setPrompt
          ));

          await reader.read().then(readChunk);
        } catch (error) {
          handleStreamError(error, addMessageBanner);
        }
      };

      await reader.read().then(readChunk);
    } else {
      const data = await response.json();

      if (!data.Answer) {
        throw new DataFormatError("Saknar Answer-fält i svaret");
      }

      setBot({ ...bot, response: data.Answer });
    }
  } catch (error) {
    console.error("Fatal error in prompt handler:", error);
    const errorMessage =
      error instanceof StreamInitError
        ? ERROR_MESSAGES.STREAM_ERROR
        : error instanceof DataFormatError
        ? ERROR_MESSAGES.FORMAT_ERROR
        : error instanceof SyntaxError
        ? ERROR_MESSAGES.PARSE_ERROR
        : ERROR_MESSAGES.NETWORK;

    addMessageBanner({
      position: "topMiddle",
      type: "failure",
      text: errorMessage,
    });
  } finally {
    if (!chat.streamResponse) {
      setChat((prev) => ({
        ...prev,
        waiting: false,
      }));
    }
  }
};
