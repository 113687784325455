import { assistantAzureRegexCheck } from "./assistantAzureRegexCheck.js";

export const recreateConversation = async (
  conversation_id,
  setChat,
  setUniqueChatId,
  setPrompt,
  chatLabelRef,
  shouldShowFileUploadButton
) => {
  setChat((prev) => ({
    ...prev,
    history: [],
    isRecreating: true,
  }));

  try {
    const response = await fetch("/api/conversations/get-conversation-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        conversation_id: conversation_id,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      // Sort and categorize data
      const sortedData = {
        messages: [],
        responses: [],
      };

      // Sort data by 'id' ASC
      data.sort((a, b) => a.id - b.id);

      // Distribute data into 'messages' and 'responses'
      data.forEach((item) => {
        if (item.type === "message") {
          sortedData.messages.push(item);
        } else if (item.type === "response") {
          sortedData.responses.push(item);
        }
      });

      const combinedData = [];
      const messages = sortedData.messages;
      const responses = sortedData.responses;

      const maxLength = Math.max(messages.length, responses.length);

      for (let i = 0; i < maxLength; i++) {
        if (i < messages.length) {
          combinedData.push(messages[i]);
        }
        if (i < responses.length) {
          combinedData.push(responses[i]);
        }
      }

      const prompt_mode = combinedData[0].prompt_mode;
      const dataStrings = combinedData.map((item) => item.data);

      setUniqueChatId(conversation_id);

      setPrompt((prev) => ({
        ...prev,
        mode: prompt_mode,
        assistantId: combinedData[0].assistant_id,
        threadId: combinedData[0].thread_id,
      }));

      setChat((prev) => ({
        ...prev,
        label: combinedData[combinedData.length - 1].label || conversation_id,
        history: [...dataStrings],
        useHistory:
          prompt_mode === "GENERAL_AZURE" ||
          assistantAzureRegexCheck(prompt_mode),
        attachments: shouldShowFileUploadButton(prompt_mode)
          ? prev.attachments
          : [],
        streamResponse:
          prompt_mode === "GENERAL_AZURE" ||
          assistantAzureRegexCheck(prompt_mode),
      }));

      chatLabelRef.current =
        combinedData[combinedData.length - 1].label || conversation_id;
    }
  } catch (error) {
    console.error("Error getting historical conversation data:", error);
  } finally {
    setChat((prev) => ({
      ...prev,
      isRecreating: false,
    }));
  }
};
