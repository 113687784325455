import { useContext } from "react";
import PromptModeInfo from "./PromptModeInfo.js";

import styles from "../styles/ChatWelcomeMessage.module.scss";

import { ApplicationContext } from "./contexts/ApplicationContext.js";

const ChatWelcomeMessage = ({ name, mode, index }) => {
  const { applicationConfig } = useContext(ApplicationContext);

  const modeConfig = applicationConfig.modes.find((m) => m.name === mode);

  const icon = modeConfig?.img || "";
  const label = modeConfig?.label || "";
  const welcomeMessage = modeConfig?.promptModeInfo?.welcomeMessage || "";

  return (
    <div key={index} className={styles.container}>
      <div className={styles.hej}>
        <img src={icon} alt="Hej ikon" width={40} />
        <span>{label}</span>
      </div>
      <p>
        Hej {name}! {welcomeMessage}
      </p>
      <PromptModeInfo mode={mode} />
    </div>
  );
};

export default ChatWelcomeMessage;
