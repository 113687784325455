import { createContext, useState, useEffect, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig.js";
import { MessageBannerContext } from "./MessageBannerContext.js";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [user, setUser] = useState({
    subject: "",
    displayName: "",
    givenName: "",
    photo: "",
    groups: "",
    authorized: false,
    settings: {},
  });

  const { addMessageBanner } = useContext(MessageBannerContext);

  const handleLogin = async (idToken, subject, accessToken) => {
    try {
      const response = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ accessToken, subject }),
      });

      if (response.ok) {
        // If login successful, set user data
        const responseBody = await response.json();

        setUser({
          ...responseBody,
          authorized: true,
        });
      } else {
        const responseBody = await response.json();
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: responseBody.message,
        });
      }

      return response;
    } catch (error) {
      // If there's an error during the fetch request, log the error to the console.
      console.error("Error:", error);
    }
  };

  // Function to handle user logout
  const handleLogout = async () => {
    try {
      const response = await fetch("/api/auth/logout", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        // If logout successful, reset user state
        setUser({
          subject: "",
          displayName: "",
          givenName: "",
          photo: "",
          groups: "",
          conversations: "",
          passedInitExam: "",
          lastPrivacyReminder: "",
          initialPolicy: "",
          initialPolicyTimestamp: "",
          settings: {},
          authorized: false,
        });

        sessionStorage.clear();
        window.location.assign("/");
      }

      return response;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const retryLogin = async () => {
      try {
        const tokens = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });

        const idToken = tokens.idToken;
        const subject = tokens.idTokenClaims.sub;
        const accessToken = tokens.accessToken;

        handleLogin(idToken, subject, accessToken);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const verifyAuthorization = async () => {
      // Send a request to the server to check if the user is authorized
      try {
        const response = await fetch("api/auth/authorize", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (response.ok) {
          // If authorized, set user data
          const responseBody = await response.json();
          setUser({
            ...responseBody,
            authorized: true,
          });
        } else {
          retryLogin();
        }

        return response;
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (!user.authorized) {
      verifyAuthorization();
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, setUser, handleLogin, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};
