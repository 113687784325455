// Importing required modules and resources
import {
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
  useCallback,
} from "react";

import { getNewChatId } from "./functions/Chat/getNewChatId.js";
import { handleQuery } from "./functions/Chat/handleQuery.js";
import { handleRenameChat } from "./functions/Chat/handleRenameChat.js";
import { handleNewChat } from "./functions/Chat/handleNewChat.js";
import { handleAbortStream } from "./functions/Chat/handleAbortStream.js";
import { handleAttachment } from "./functions/Chat/handleAttachment.js";
import { recreateConversation } from "./functions/Chat/recreateConversation.js";
import { assistantAzureRegexCheck } from "./functions/Chat/assistantAzureRegexCheck.js";

import styles from "../styles/Chat.module.scss";
import "../styles/markdown.scss";

import { AuthContext } from "./contexts/AuthContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";
import { ModalContext } from "./contexts/ModalContext.js";
import { ApplicationContext } from "./contexts/ApplicationContext.js";

import Modal from "./Modal.js";
import ChatMenu from "./ChatMenu.js";
import PromptTextarea from "./PromptTextarea.js";
import ChatMessage from "./ChatMessage.js";
import ChatResponse from "./ChatResponse.js";
import ChatWelcomeMessage from "./ChatWelcomeMessage.js";
import ChatTopper from "./ChatTopper.js";
import ChatAttachments from "./ChatAttachments.js";
import ChatWidthController from "./ChatWidthController.js";

import sendIcon from "../img/send.svg";
import chatBubbles from "../img/chat-bubbles.svg";
import addImageIcon from "../img/image-with-plus.svg";
import addFileIcon from "../img/file-with-plus.svg";
import crossIcon from "../img/cross.svg";

const Chat = () => {
  const { user } = useContext(AuthContext);
  const { applicationConfig } = useContext(ApplicationContext);
  const { addMessageBanner } = useContext(MessageBannerContext);
  const { setDisplayModal } = useContext(ModalContext);
  const [uniqueChatId, setUniqueChatId] = useState("");
  const [chatWindowWidth, setChatWindowWidth] = useState(900);

  useEffect(() => {
    // Show InitialPolicy modal if it has not been accepted before
    // or if recurringInitialPolicy is set to true in applicationConfig.
    if (
      user.authorized &&
      (!user.initialPolicy ||
        applicationConfig.settings?.recurringInitialPolicy)
    ) {
      setDisplayModal("InitialPolicy");
    }
  }, [user.authorized]);

  // Update Chat's window width when user settings are loaded
  useEffect(() => {
    if (user.authorized && user.settings) {
      let userSettings = user.settings;

      if (typeof user.settings === "string") {
        try {
          userSettings = JSON.parse(user.settings);
        } catch (error) {
          console.error("Error parsing user settings:", error);
        }
      }

      if (
        userSettings.chatWindowWidth &&
        userSettings.chatWindowWidth !== chatWindowWidth
      ) {
        setChatWindowWidth(userSettings.chatWindowWidth);
      }
    }
  }, [user.settings]);

  useEffect(() => {
    // Get a new chat ID
    getNewChatId(addMessageBanner).then((id) => {
      setUniqueChatId(id);
    });
  }, []);

  const [bot, setBot] = useState({
    response: "",
    sources: [
      {
        content: "",
        file: "",
        url: "",
      },
    ],
  });

  const [prompt, setPrompt] = useState({
    content: "",
    base64Image: "",
    attachmentsToUpload: [],
    mode: applicationConfig.modes[0].name,
    assistantId: applicationConfig.modes[0].assistantId || "",
    threadId: "",
    nerResponse: "",
  });

  const [chat, setChat] = useState({
    label: "Namnlös chatt",
    requestAiLabel: false,
    waiting: false,
    history: [],
    useHistory:
      prompt.mode === "GENERAL_AZURE" || assistantAzureRegexCheck(prompt.mode),
    attachments: [],
    useSources: false,
    currentSourceIndex: 0,
    latestPrompt: "",
    streamResponse: true,
    isRecreating: false,
  });

  const chatLabelRef = useRef(chat.label); // Ref to track chat label

  const [autoScroll, setAutoScroll] = useState(true);
  const lastScrollTop = useRef(window.scrollY);

  const scrollToBottom = () => {
    if (autoScroll) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "auto",
      });
    }
  };

  const isAtBottom = () => {
    return (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 2
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop < lastScrollTop.current) {
        // User is scrolling up, abort auto scrolling
        setAutoScroll(false);
      } else if (isAtBottom()) {
        // User is at absolute bottom, resume auto scrolling
        setAutoScroll(true);
      }
      lastScrollTop.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (bot.response || (chat.useHistory && chat.history.length > 0)) {
      scrollToBottom();
    }
  }, [bot.response, chat.history, autoScroll]);

  // Handles chat.requestAiLabel
  const hasLabel = useMemo(() => {
    return (
      prompt.mode === "GENERAL_AZURE" || assistantAzureRegexCheck(prompt.mode)
    );
  }, [prompt.mode]);

  const shouldRequestNewLabel = useCallback(() => {
    if (!user.authorized || !user.conversations) {
      return false;
    }

    return hasLabel && chat.label === "Namnlös chatt";
  }, [user.authorized, user.conversations, hasLabel, chat.label]);

  useEffect(() => {
    if (shouldRequestNewLabel()) {
      setChat((prev) => ({
        ...prev,
        requestAiLabel: true,
      }));
    } else {
      setChat((prev) => ({
        ...prev,
        requestAiLabel: false,
      }));
    }
  }, [shouldRequestNewLabel]);

  const doHandleQuery = (e) => {
    handleQuery(
      e,
      uniqueChatId,
      bot,
      setBot,
      chat,
      setChat,
      prompt,
      setPrompt,
      addMessageBanner,
      chatLabelRef
    );
  };

  const doHandleNewChat = async (newMode, assistantId) => {
    if (
      prompt.mode === "GENERAL_AZURE" ||
      assistantAzureRegexCheck(prompt.mode)
    ) {
      await handleAbortStream(uniqueChatId);
    }

    if (!newMode) {
      newMode = prompt.mode;
    }

    if (!assistantId) {
      assistantId = prompt.assistantId;
    }

    // TODO: Remove this when there are no more GENERAL_AZURE conversations left
    if (newMode === "GENERAL_AZURE") {
      newMode = "ASSISTANT_AZURE__0";
    }

    handleNewChat(
      newMode,
      assistantId,
      getNewChatId,
      addMessageBanner,
      setUniqueChatId,
      setBot,
      prompt,
      setPrompt,
      setChat,
      chatLabelRef,
      shouldShowFileUploadButton
    );
  };

  const doRecreateConversation = async (conversation_id) => {
    if (
      prompt.mode === "GENERAL_AZURE" ||
      assistantAzureRegexCheck(prompt.mode)
    ) {
      await handleAbortStream(uniqueChatId);
    }

    recreateConversation(
      conversation_id,
      setChat,
      setUniqueChatId,
      setPrompt,
      chatLabelRef,
      shouldShowFileUploadButton
    );
  };

  const hiddenFileUploadInput = useRef(null);
  const hiddenImageUploadInput = useRef(null);

  const allowedFileTypes = [
    ".c",
    ".cs",
    ".cpp",
    ".doc",
    ".docx",
    ".html",
    ".java",
    ".json",
    ".md",
    ".pdf",
    ".php",
    ".pptx",
    ".py",
    ".rb",
    ".tex",
    ".txt",
    ".css",
    ".js",
    ".sh",
    ".ts",
    ".csv",
    ".tar",
    ".xlsx",
    ".xml",
    ".zip",
  ];

  const doHandleAttachment = (e) => {
    handleAttachment(e, prompt, setPrompt, chat, setChat, addMessageBanner);
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject("Error converting image to Base64:", error);
      };
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      const base64Image = await convertImageToBase64(file);
      setPrompt((prev) => {
        return {
          ...prev,
          base64Image: base64Image,
        };
      });
    } else {
      console.error("File is not an image.");
    }
  };

  const handleClearImageUpload = () => {
    setPrompt((prev) => {
      return {
        ...prev,
        base64Image: "",
      };
    });
  };

  // Is also passed to reacreateConversation and handleNewChat
  // to determine if attachments should be sent with the prompt
  const shouldShowFileUploadButton = (prop = prompt.mode) => {
    const currentMode = applicationConfig.modes.find(
      (mode) => mode.name === prop
    );
    return currentMode?.extras?.fileUpload === true;
  };

  const shouldShowImageUploadButton = () => {
    const currentMode = applicationConfig.modes.find(
      (mode) => mode.name === prompt.mode
    );
    return currentMode?.extras?.imageUpload === true;
  };

  const memoizedChatHistory = useMemo(() => {
    if (chat.useHistory && chat.history && chat.history.length > 0) {
      return chat.history.map((item, index) => {
        return index % 2 === 0 ? (
          <ChatMessage key={index} content={item} index={index} user={user} />
        ) : (
          <ChatResponse
            key={index}
            content={item}
            mode={prompt.mode}
            index={index}
          />
        );
      });
    }
    return null;
  }, [chat.useHistory, chat.history, prompt.mode, user]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <ChatMenu
        prompt={prompt}
        setPrompt={setPrompt}
        setChat={setChat}
        uniqueChatId={uniqueChatId}
        doHandleNewChat={doHandleNewChat}
        doRecreateConversation={doRecreateConversation}
      />
      <div
        className={styles.container}
        style={{ width: `${chatWindowWidth}px` }}
      >
        <ChatWidthController
          chatWindowWidth={chatWindowWidth}
          setChatWindowWidth={setChatWindowWidth}
        />
        <Modal prompt={chat.latestPrompt} response={prompt.nerResponse} />
        <ChatTopper doHandleNewChat={doHandleNewChat} />
        <div className={styles.header}>
          {(prompt.mode === "GENERAL_AZURE" ||
            assistantAzureRegexCheck(prompt.mode)) &&
            Boolean(user.conversations) && (
              <div className={styles.label}>
                <img src={chatBubbles} alt="Chat" width={20} />
                <input
                  type="text"
                  size={33}
                  maxLength={35}
                  value={chat.label}
                  onChange={(e) =>
                    setChat({
                      ...chat,
                      label: e.target.value,
                    })
                  }
                  onBlur={() =>
                    handleRenameChat(
                      chat,
                      chatLabelRef,
                      uniqueChatId,
                      addMessageBanner
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.target.blur();
                    }
                  }}
                />
              </div>
            )}
        </div>
        <ChatWelcomeMessage
          name={user.givenName}
          mode={prompt.mode}
          index={9999}
          isRecreating={chat.isRecreating}
        />

        {memoizedChatHistory}

        {/* This displays the latest response while it is being generated, before it, upon completeion, becomes part of the history */}
        {chat.useHistory && chat.history && chat.history.length % 2 !== 0 && (
          <ChatResponse
            content={bot.response}
            mode={prompt.mode}
            waiting={chat.waiting}
            index={chat.history.length}
            handleAbortStream={handleAbortStream}
            uniqueChatId={uniqueChatId}
          />
        )}

        {!chat.useHistory && chat.latestPrompt && (
          <ChatMessage content={chat.latestPrompt} user={user} />
        )}

        {!chat.useHistory && bot.response && (
          <ChatResponse content={bot.response} mode={prompt.mode} />
        )}

        {/* Display spinner while waiting if response is not streamed */}
        {!chat.useHistory && !bot.response && chat.waiting && (
          <ChatResponse
            content={"Genererar svar..."} // This is needed to prevent a visual bug
            mode={prompt.mode}
            waiting={chat.waiting}
          />
        )}

        {shouldShowFileUploadButton() && (
          <div className={styles.extras}>
            <button
              className={styles.upload}
              onClick={() => hiddenFileUploadInput.current.click()}
            >
              <input
                type="file"
                accept={allowedFileTypes.join(",")}
                ref={hiddenFileUploadInput}
                onChange={doHandleAttachment}
                style={{ display: "none" }}
                multiple
              />
              <img src={addFileIcon} width={17} alt="Bifoga filer" />
              <span>Bifoga filer</span>
            </button>

            {user.authorized && (
              <ChatAttachments prompt={prompt} chat={chat} setChat={setChat} />
            )}
          </div>
        )}

        {shouldShowImageUploadButton() && (
          <div className={styles.extras}>
            {!prompt.base64Image ? (
              <>
                <button
                  className={styles.upload}
                  onClick={() => hiddenImageUploadInput.current.click()}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={hiddenImageUploadInput}
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <img src={addImageIcon} width={17} alt="Bifoga bild" />
                  <span>Bifoga bild</span>
                </button>
              </>
            ) : (
              <div className={styles.uploaded}>
                <img
                  className={styles.uploadedImage}
                  src={prompt.base64Image}
                  alt="Bifogad bild"
                />
                <button
                  className={styles.clear}
                  onClick={() => handleClearImageUpload()}
                >
                  <img
                    className={styles.clearIcon}
                    src={crossIcon}
                    width={20}
                    alt="Ta bort bild"
                  />
                </button>
              </div>
            )}
          </div>
        )}

        <form onSubmit={doHandleQuery}>
          <PromptTextarea
            prompt={prompt}
            setPrompt={setPrompt}
            chat={chat}
            doHandleQuery={doHandleQuery}
          />
          <button type="submit" disabled={chat.waiting}>
            <img
              src={sendIcon}
              alt="Skicka"
              width={40}
              style={{
                opacity: prompt.content && !chat.waiting ? 1 : 0.3,
              }}
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;
